import React, { useEffect } from "react";
import { Modal, IconButton, Grid, Box, Typography } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import categoryBrandStyles from "./InventoryManagmentStyle";
import useCategoryData from "../../data/inventory/useCategoryData";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import salesStyle from "../SalesPersonManagement/salesStyle";

import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
  ADD_CATEGORY,
} from "../../utilitis/header";

const AddCategory = ({
  open,
  handleClose,
  title,
  values,
  handleInputChange,
  setValues,
  submitData,
  id,
  nameError,
  setNameError,
  otherChargeError,
  setOtherChargeError,
  typeError,
  setTypeError,
  serviceTypeError,
  setServiceTypeError,
  timeslotDayError,
  setTimeslotDayError,
  timeslotNightError,
  setTimeslotNightError,
  setProcessingTimeError,
  processingTimeError,
  handleCheckBox,
}) => {
  const categoryStyle = categoryBrandStyles();
  const orderStyle = OrderManagmentStyle();
  const salesStyles = salesStyle();
  const params = useParams();
  const categoryType = [
    { id: 1, name: "Product" },
    { id: 2, name: "Service" },
  ];

  const partType = [
    { id: 1, name: "Exterior" },
    { id: 2, name: "Interior" },
  ];

  const serviceType = [
    { id: 0, name: "Not Applicable" },
    { id: 1, name: "Service at Home" },
    { id: 2, name: "Nearest Drop" },
    { id: 3, name: "Service at Home Or Nearest Drop" },
  ]

  const { data: catData, isSuccess: catDataSuccess } = isNaN(id)
    ? ""
    : params.name
      ? useCategoryData.useSubcategoryList(id)
      : useCategoryData.useCategoryList(id);

  useEffect(() => {

    if (catDataSuccess) {

      let time_slots_day = '';
      let time_slots_night = '';

      if (catData.time_slots === null || catData.time_slots === undefined) {
        time_slots_day = '8:00-19:59';
        time_slots_night = '20:00-7:59';
      } else {
        time_slots_day = catData.time_slots.hasOwnProperty('day') ? catData.time_slots.day : '8:00-19:59';
        time_slots_night = catData.time_slots.hasOwnProperty('night') ? catData.time_slots.night : '20:00-7:59';
      }
      setValues({
        ...values,
        name: catData && catData.name,
        usp: catData && catData.usp,
        other_charges: catData && catData.other_charges,
        type: catData && catData.type,
        part_type: catData && catData.part_type,
        service_type: catData && catData.service_type,
        night_service: catData && catData.night_service,
        time_slots_day: time_slots_day,
        time_slots_night: time_slots_night,
        display: catData && catData.display,
        processing_time: catData && catData.processing_time,
      });
    }
  }, [catData, catDataSuccess]);



  const handleChange = (e) => {
    let targetName = e.target.name;

    let targetValue = e.target.value;

    if (targetName === "type") {
      handleInputChange(e);
      setTypeError("");
    }
    if (targetName === "name") {
      if (/^[a-z ,.&'-]+$/i.test(targetValue)) {
        handleInputChange(e);
        setNameError("");
      } else {
        setValues({
          ...values,
          name: targetValue.substring(0, targetValue.length - 1),
        });
      }
    }
    if (targetName === "other_charges") {
      handleInputChange(e);
      setOtherChargeError("");
    }

    const isValid = /^[0-9]*$/.test(targetValue);

    if (targetName === "processing_time" && isValid) {
      handleInputChange(e);
      setProcessingTimeError("");
    }

    if (targetName === "part_type") {
      handleInputChange(e);
    }
    if (targetName === "service_type") {
      handleInputChange(e);
      setServiceTypeError("");
    }
    if (targetName === "night_service") {
      handleCheckBox(e);
    }
    if (targetName === "display") {
      handleCheckBox(e);
    }
    if (targetName === "time_slots_day") {
      handleInputChange(e);
      setTimeslotDayError("");
    }
    if (targetName === "time_slots_night") {
      handleInputChange(e);
      setTimeslotNightError("");
    }
  };

  return (
    <>
      <Modal open={open}>

        <div className="modalWrap">
          <Box>
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={salesStyles.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={handleClose}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>



            {/* <Box className={categoryStyle.boxStyle}>
          <form style={{ width: "100%" }}>
            <Grid>
              <div
                // className={customStyle.subHeaderParent}
                style={{
                  backgroundColor: "#FEDD17",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginTop: "5px" }}>
                  <Sub data={title } />
                </Box>
              </div> */}

            {/* <div className={categoryStyle.formStyle}>
                <Grid
                  container
                  alignItems="center"
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                > */}
            <div style={{ width: "100%", padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Type
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{ marginTop: "15px" }}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="type"
                    error={typeError}
                    label="Category Type"
                    value={values.type}
                    options={categoryType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    {params.name
                      ? "Name of the Sub Category"
                      : "Name of the Category"}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="name"
                    type={"text"}
                    value={values.name}
                    onChange={handleChange}
                    error={nameError}
                    inputProps={{ maxLength: 100 }}
                    placeholder="Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    USP
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.TextArea
                    className={categoryStyle.textArea}
                    name="usp"
                    value={values.usp}
                    placeholder={'USP'}
                    onChange={handleInputChange}
                    // error={errors.usp}
                    maxLength="250"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Other Charges
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    placeholder={'Other Charges'}

                    name="other_charges"
                    value={values.other_charges}
                    onChange={handleChange}
                    // error={otherChargeError}
                    type="text"
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Part Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="part_type"
                    label="Part Type"
                    value={values.part_type}
                    options={partType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Service Type
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="service_type"
                    label="Service Type"
                    value={values.service_type}
                    error={serviceTypeError}
                    options={serviceType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Available at Night
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.CheckBoxLabel
                    checked={values && values.night_service}
                    name="night_service"
                    value={values.night_service}
                    onChange={handleChange}
                    className={categoryStyle.chechBox}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Display
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.CheckBoxLabel
                    checked={values && values.display}
                    name="display"
                    value={values.display}
                    onChange={handleChange}
                    className={categoryStyle.chechBox}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Day slot
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="time_slots_day"
                    placeholder={'Day Slot'}

                    value={values.time_slots_day}
                    onChange={handleChange}
                    error={timeslotDayError}
                    type="text"
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Night slot
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="time_slots_night"
                    value={values.time_slots_night}
                    onChange={handleChange}
                    error={timeslotNightError}
                    type="text"
                    placeholder={'Night Slot'}
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    ETA
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="processing_time"
                    value={values.processing_time}
                    onChange={handleChange}
                    error={processingTimeError}
                    type="text"
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid xs={12} sm={6}>
                    <IconButs
                      ibtname={
                        typeof id == "number"
                          ? ADD_CATEGORY
                          : FORM_BUTTON_MANAGEMENT
                      }
                      className={categoryStyle.formButtonAdd}
                      onClick={submitData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <IconButs
                      onClick={handleClose}
                      ibtname={FORMONE_BUTTON_MANAGEMENT}
                      className={categoryStyle.cancelButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* {messageOpen ? (
                <>
                  <div>
                    <Controls.MessageBox message={messageContent} />
                  </div>
                </>
              ) : (
                ""
              )} */}
            {/* </Grid> */}
            {/* </form> */}
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default AddCategory;
